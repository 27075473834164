import { Breadcrumb, Container, Segment, Header } from "semantic-ui-react"

export default function NotFound(){
    return (
        <>
            <Container>
                <Segment>
                    <Header as="h1" className="centered text">
                        Page not found.
                    </Header>
                </Segment>
            </Container>
        </>
    )
}