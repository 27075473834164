import { Breadcrumb, Segment, Header, Container, Grid, GridColumn, Loader, Progress } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import NotFound from "./NotFound";
import Nameplate from "./Nameplate";
import TimeAgo from "react-timeago";
import fetchCache from "../Cache";

function BundleCard(props){
    let bundle = props.bundle;

    let url;
    try {
        url = new URL(bundle.item_url).hostname;
    } catch(e) {
        url = null;
    }

    return (
        <GridColumn width={4} className="centered text bundle">
            <Header className="centered text" as="h3">
                <Link to={`/buy/${bundle.id}`}>{bundle.title}</Link>
            </Header>

            from <a href={bundle.item_url} rel="noreferrer noopener" target="_blank">{url}</a><br />
            hosted by <Nameplate id={bundle.owner} /><br />
            {bundle.cancelled ? "cancelled" : "ended"} <TimeAgo date={bundle.ended} /><br />
            <Progress value={bundle.reservations.length} total={bundle.max_slots} error={(bundle.reservations.length/bundle.max_slots)*100 >= 100}>
                {bundle.reservations.length}/{bundle.max_slots} slots filled, {bundle.required_slots} required
            </Progress>
        </GridColumn>
    )
}

export default function Bundles(){
    let [bundles, setBundles] = useState(null);

    useEffect(() => {
        if( ! bundles && bundles !== false ){
            bundles = false;

            (async () => {
                let bundles = (await fetchCache(`/api/bundle`));

                if( ! bundles.ok ){
                    return setBundles(false);
                }

                setBundles(bundles.json);
            })();
        }
    })

    if( ! bundles ){
        return (
            <Container>
                <Segment>
                    <Loader active inline="centered" />
                </Segment>
            </Container>
        )
    } else if( bundles === false ){
        return <NotFound />
    } else {
        bundles = bundles.map(bundle => (
            <BundleCard key={bundle.id} bundle={bundle} />
        ));
    }

    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Section as={Link} to="/">Home</Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right angle" />
                    <Breadcrumb.Section><b>Buys</b></Breadcrumb.Section>
                </Breadcrumb>

                <Header as='h1'>
                    <FontAwesomeIcon icon={faPlus} /> All Group Buys
                </Header>

                <Grid as={Segment} divided>
                    {bundles}
                </Grid>
            </Container>
        </>
    )
}