export default async function fetchCache(url){
    let cache = localStorage.getItem(url);

    if( cache ){
        console.log("fetchCache: " + url + " (hit)");
        return JSON.parse(cache);
    }

    console.log("fetchCache: " + url + " (miss)");

    let resp = (await fetch(url));
    let item = { ok: resp.ok, json: await resp.json() };

    if( resp.ok ){
        localStorage.setItem(url, JSON.stringify(item));
    }

    return item;
}