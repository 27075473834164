import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from "./components/Layout.jsx";
import Bundles from "./components/Bundles.jsx";
import Bundle from './components/Bundle.jsx';
import Splits from './components/Splits.jsx';
import Split from './components/Split.jsx';
import NotFound from './components/NotFound.jsx';

import 'semantic-ui-css/semantic.min.css'
import Home from './components/Home.jsx';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="buy" element={<Bundles />} />
            <Route path="buy/:id" element={<Bundle />} />
            <Route path="split" element={<Splits />} />
            <Route path="split/:id" element={<Split />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
