import { Breadcrumb, Container, Segment, Header, Progress, Loader } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { useEffect, useState } from "react";
import Nameplate from "./Nameplate";
import TimeAgo from "react-timeago";
import Markdown from "react-markdown";
import fetchCache from "../Cache";

export default function Bundle(){
    const [bundle, setBundle] = useState(null);
    const {id} = useParams();

    useEffect(() => {
        if( ! bundle ){
            (async () => {
                let bundle = (await fetchCache(`/api/bundle/${id}`));

                if( ! bundle.ok ){
                    return setBundle(false);
                }

                setBundle(bundle.json);
            })();
        }
    });

    if( bundle ){
        let url;

        try {
            url = new URL(bundle.item_url).hostname;
        } catch(e) {
            url = null;
        }

        let reservers = bundle.reservations.map(i => <Nameplate type="avatar" id={i.user} />)

        let comments = bundle.comments.map(i => <>
            <Segment>
                <Nameplate id={i.owner} />

                <Markdown>
                    {i.body}
                </Markdown>

                <p className="post-time">Posted <TimeAgo date={i.time_posted}></TimeAgo>{i.time_edited != i.time_posted ? (<> &middot; edited <TimeAgo date={i.time_edited}></TimeAgo></>) : null}</p>
            </Segment>
        </>);

        if( bundle.comments.length === 0 ){
            comments = <Segment>No comments.</Segment>
        }

        return (
            <>
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Section as={Link} to="/">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                        <Breadcrumb.Section as={Link} to="/buy">Buys</Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                        <Breadcrumb.Section><b>{bundle.title}</b></Breadcrumb.Section>
                    </Breadcrumb>

                    <Segment>
                        <Header as="h1">
                            {bundle.title}
                        </Header>

                        from <a href={bundle.item_url} rel="noopener noreferrer" target="_blank">{url}</a> &middot; hosted by <Nameplate id={bundle.owner} /> &middot; {bundle.cancelled ? "cancelled" : "ended"} <TimeAgo date={bundle.ended} />

                        <hr />

                        <Markdown>{bundle.description}</Markdown>

                        <hr />

                        {reservers}

                        <hr />

                        <Progress value={Math.round((bundle.reservations.length/bundle.max_slots)*100)} total={100} error={(bundle.reservations.length/bundle.max_slots)*100 >= 100}>
                            {bundle.reservations.length}/{bundle.max_slots} slots filled, {bundle.required_slots} required
                        </Progress>

                        <hr />

                        <Header as="h3">Comments ({bundle.comments.length})</Header>

                        {comments}
                    </Segment>
                </Container>
            </>
        )
    } else if( bundle === false ) {
        return (
            <NotFound />
        )
    } else {
        return (
            <Container>
                <Segment>
                    <Loader active inline="centered" />
                </Segment>
            </Container>
        )
    }
}