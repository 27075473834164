import { faCrop, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Segment, Container, Header } from "semantic-ui-react";

export default function Home(){
    return (
        <>
            <Container>
                <Segment>
                    <Header as="h1" className="centered text">
                        <Link to="/buy">
                            <FontAwesomeIcon icon={faPlus} /> Archived group buys
                        </Link>
                    </Header>
                </Segment>
            </Container>

            <br />

            <Container>
                <Segment>
                    <Header as="h1" className="centered text">
                        <Link to="/split">
                            <FontAwesomeIcon icon={faCrop} /> Archived bundle splits
                        </Link>
                    </Header>
                </Segment>
            </Container>
        </>
    )
}