import { Container, Segment, Loader, Breadcrumb, Header, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { faCrop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFound from "./NotFound";
import { useState, useEffect } from "react";
import Nameplate from "./Nameplate";
import TimeAgo from "react-timeago";
import fetchCache from "../Cache";

function SplitCard(props){
    let split = props.split;

    let url;
    try {
        url = new URL(split.item_url).hostname;
    } catch(e) {
        url = null;
    }

    return (
        <>
            <Grid.Column width={4} className="centered text bundle">
                <Header className="centered text" as="h3">
                    <Link to={`/split/${split.id}`}>{split.title}</Link>
                </Header>

                from <a href={split.item_url} rel="noreferrer noopener" target="_blank">{url}</a><br />
                hosted by <Nameplate id={split.owner} /><br />
                {split.cancelled ? "cancelled" : "ended"} <TimeAgo date={split.ended} /><br />
                {split.items.length} item(s)
            </Grid.Column>
        </>
    )
}

export default function Splits(){
    let [splits, setSplits] = useState(null);

    useEffect(() => {
        if( ! splits && splits !== false ){
            splits = false;

            (async () => {
                let splits = (await fetchCache(`/api/split`));

                if( ! splits.ok ){
                    return setSplits(false);
                }

                setSplits(splits.json);
            })();
        }
    })

    if( ! splits ){
        return (
            <Container>
                <Segment>
                    <Loader active inline="centered" />
                </Segment>
            </Container>
        )
    } else if( splits === false ){
        return <NotFound />
    } else {
        splits = splits.map(split => <SplitCard split={split} />);
    }

    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Section as={Link} to="/">Home</Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right angle" />
                    <Breadcrumb.Section><b>Bundle Splits</b></Breadcrumb.Section>
                </Breadcrumb>

                <Header as='h1'>
                    <FontAwesomeIcon icon={faCrop} /> All Bundle Splits
                </Header>

                <Grid as={Segment} divided>
                    {splits}
                </Grid>
            </Container>
        </>
    )
}