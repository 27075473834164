import { Link, Outlet } from "react-router-dom";
import { Menu, MenuItem, Segment, Container, Header } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrop, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Layout(){
    return (
        <>
            <Segment inverted className="backdrop">
                <Menu inverted pointing borderless fixed attached>
                    <Container>
                        <MenuItem as={Link} to="/">
                            <img src="/favicon.ico" className="brand"></img>
                            <b>GroupBundl.es</b>
                        </MenuItem>
                        <MenuItem as={Link} to="/buy">
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="desktop-only">&nbsp; &nbsp; Buys</span>
                        </MenuItem>
                        <MenuItem as={Link} to="/split">
                            <FontAwesomeIcon icon={faCrop} />
                            <span className="desktop-only">&nbsp; &nbsp; Splits</span>
                        </MenuItem>
                    </Container>
                </Menu>

                <Segment inverted className="home-masthead">
                    <Container className='centered text'>
                        <Header inverted as='h1' className='centered'>GroupBundl.es made group buys easy.</Header>

                        GroupBundl.es ran from 2017 to the end of 2023 and was a web site that automated video game group buys. This is a read-only, archived version of the site. Thanks for participating!<br /><br />

                        If you are looking to participate in group buys, please consider visiting <a href="https://steamcommunity.com/groups/groupbuys" target="_blank" rel="noopener noreferrer">the Group Buys Steam group</a>. To trade bundle leftovers for wishlist games, visit <a href="https://barter.vg" target="_blank" rel="noopener noreferrer">Barter.vg</a>.<br />
                    </Container>
                </Segment>

                <Segment>
                    <Outlet />
                </Segment>

                <Segment vertical inverted className="footer">
                    <Container className="text centered">
                        &copy; 2018-{new Date().getFullYear()} <a href="https://cutie.cafe/" target="_blank" rel="noopener noreferrer">Cutie Caf&eacute;</a>. All rights reserved.<br />
                        For inquiries about this site, please email developers at cutie dot cafe.
                    </Container>
                </Segment>
            </Segment>
        </>
    )
}