import { Breadcrumb, Container, Segment, Header, Progress, Loader, Grid, GridColumn } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { useEffect, useState } from "react";
import Nameplate from "./Nameplate";
import TimeAgo from "react-timeago";
import Markdown from "react-markdown";
import fetchCache from "../Cache";

let dollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export default function Split(){
    const [split, setSplit] = useState(null);
    const {id} = useParams();

    useEffect(() => {
        if( ! split ){
            (async () => {
                let split = (await fetchCache(`/api/split/${id}`));

                if( ! split.ok ){
                    return setSplit(false);
                }

                setSplit(split.json);
            })();
        }
    });

    if( split ){
        let url;

        try {
            url = new URL(split.item_url).hostname;
        } catch(e) {
            url = null;
        }

        let items = split.items.map(i => <>
            <GridColumn width={4} className="centered text bundle">
                <Header as="h3">{i.title}</Header>
                <Header as="h4">{dollarFormat.format(i.price)}</Header>
                {i.reserver ? <>Reserved by <Nameplate id={i.reserver} /></> : <></>}
            </GridColumn>
        </>)

        return (
            <>
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Section as={Link} to="/">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                        <Breadcrumb.Section as={Link} to="/split">Splits</Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                        <Breadcrumb.Section><b>{split.title}</b></Breadcrumb.Section>
                    </Breadcrumb>

                    <Segment>
                        <Header as="h1">
                            {split.title}
                        </Header>

                        from <a href={split.item_url} rel="noopener noreferrer" target="_blank">{url}</a> &middot; hosted by <Nameplate id={split.owner} /> &middot; {split.cancelled ? "cancelled" : "ended"} <TimeAgo date={split.ended} />

                        <hr />

                        <Markdown>{split.description}</Markdown>

                        <hr />

                        <Grid>
                            {items}
                        </Grid>
                    </Segment>
                </Container>
            </>
        )
    } else if( split === false ) {
        return (
            <NotFound />
        )
    } else {
        return (
            <Container>
                <Segment>
                    <Loader active inline="centered" />
                </Segment>
            </Container>
        )
    }
}