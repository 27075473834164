import { useState, useEffect } from "react";
import { Popup, Image } from "semantic-ui-react";
import fetchCache from "../Cache";

const userCache = {};
const userPendingRequests = {};

export default function Nameplate(props){
    let type = props.type || "text";

    let [user, setUser] = useState(null);

    useEffect(() => {
        if( props.id in userCache ){
            setUser(userCache[props.id]);
            return;
        }

        if( ! user && user !== false ){
            user = false;

            if( userPendingRequests[props.id] ){
                userPendingRequests[props.id].push(setUser);
                return;
            }

            userPendingRequests[props.id] = [];

            (async () => {
                if( userCache[props.id] ){
                    setUser(userCache[props.id]);
                    return;
                }

                let user = (await fetchCache(`/api/user/${props.id}`));

                if( ! user.ok ){
                    return setUser(false);
                }

                user = user.json;
                userCache[props.id] = user;
                setUser(user);

                for( let i of userPendingRequests[props.id] ){
                    i(user);
                }
            })();
        }
    });

    if( user ){
        if( type == "text" ){
            return (
                <a href={`https://steamcommunity.com/profiles/${user.steamid}`} rel="noopener noreferrer" target="_blank">{user.persona.name}</a>
            )
        } else if( type == "avatar" ){
            return <>
                <a href={`https://steamcommunity.com/profiles/${user.steamid}`} rel="noopener noreferrer" target="_blank">
                    <Popup
                        content={user}
                        key={props.id}
                        header={user.persona.name}
                        trigger={<Image src={user.persona.avatar} className="avatar" />}
                    />
                </a>
            </>
        } else {
            return <>...</>
        }
    } else if( user === false ){
        return <>user {props.id}</>
    } else {
        return <>...</>
    }
}